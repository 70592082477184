<template>
  <b-modal
    id="modal-detail"
    size="xl"
    centered
    scrollable
    :title="title"
    header-bg-variant="primary"
    header-text-variant="light"
  >
    <div>
      <!-- <b-row>
        <b-col cols="12" md="12" lg="12">
          <h4><strong>Data Absensi Kedatangan</strong></h4>
          <hr />
        </b-col>
      </b-row> -->

      <b-row class="mt-3">
        <b-col md="3">
          <b-form-group
            :label="$store.state.table.perHalaman"
            label-for="per-page-select"
            label-cols-md="6"
            label-align-md="left"
            label-size="md"
            style="background-color: "
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="md"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6" offset-md="3">
          <b-form-group
            :label="$store.state.table.cari"
            label-for="filter-input"
            label-cols-md="3"
            label-align-md="right"
            label-size="md"
          >
            <b-input-group size="md">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$store.state.table.cariHolder"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                  variant="danger"
                  >{{ $store.state.table.hapusCari }}</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            stacked="md"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(no)="item">
              {{ item.index + 1 }}
            </template>
            <template #cell(tanggalLembur)="item">
              <div>{{ moment(item.item.tanggalLembur).format("ll") }}</div>
            </template>
            <template #cell(actions)="item">
              <b-button
                variant="danger"
                class="mr-1"
                v-c-tooltip.hover.click="'Hapus Data'"
                v-b-modal.modal-hapus
                @click="dataHapus = item.item"
                ><CIcon name="cil-trash" />{{ item.actions }}</b-button
              >
              {{ item.actions }}
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
      <div>
        <b-modal
          id="modal-hapus"
          size="lg"
          centered
          scrollable
          title="Hapus Lembur Karyawan"
          header-bg-variant="danger"
          header-text-variant="light"
        >
          <div>
            <p>Apakah anda yakin menghapus data lembur ini?</p>
          </div>
          <template #modal-footer>
            <b-button variant="secondary" @click="$bvModal.hide('modal-hapus')">
              Batal
            </b-button>
            <b-button variant="danger" @click="hapus()"> Yakin </b-button>
          </template>
        </b-modal>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
        Batal
      </b-button>
    </template>
    <!-- <ModalTelat :dataHapus="dataHapus" :data="data" /> -->
  </b-modal>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
// import ModalTelat from "./modalUpdate.vue";
export default {
  name: "tabAbsensiKedatangan",
  props: ["dataLembur", "detail"],
  components: {
    // ModalTelat,
  },
  data() {
    return {
      ipBackend,
      title: null,
      moment,
      bulan: "",
      tahun: "",
      src1: "",
      src2: "",
      dataHapus: null,
      items: [
        {
          no: "-",
          tanggal: "-",
          jamMasuk: "-",
          jamKeluar: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "tanggalLembur",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jumlahJam",
          label: "Jam Lembur",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "nominal",
          label: "Nominal Lembur",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          sortable: true,
          sortDirection: "desc",
          class: "table-option-2 text-center ",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      let vm = this;

      let absensi = await axios.post(
        ipBackend + "lembur/listLemburBydataKaryawanId",
        vm.dataLembur
      );
      // console.log(absensi.data, "aa");
      vm.items = absensi.data.data;
    },
    hapus() {
      let vm = this;
      vm.dataHapus.id = vm.dataHapus.lemburId;
      axios
        .post(ipBackend + "lembur/delete", vm.dataHapus)
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "sukses") {
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL HAPUS DATA LEMBUR KARYAWAN",
                showing: true,
              });
              this.$bvModal.hide("modal-hapus");
              this.getData();
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$bvModal.hide("modal-hapus");
        });
    },
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (newVal != oldVal) {
        // console.log("iki");
        this.title =
          "Detail Lembur Karyawan a.n " + this.dataLembur.namaKaryawan;
        this.getData();
      }
    },
  },
};
</script>
