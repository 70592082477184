<template>
  <div>
    <b-modal
      id="modal-input"
      centered
      title="Input Data Lembur Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Karyawan" label-cols-md="3">
          <multiselect
            v-model="data.karyawan"
            :options="listKaryawan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih Karyawan --"
            label="namaKaryawan"
            track-by="dataKaryawanId"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Tanggal Lembur" label-cols-md="3">
          <date-picker
            v-model="data.tanggal"
            style="width: 100%"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Lembur (jam)" label-cols-md="3">
          <b-form-select
            v-model="data.jumlahJam"
            :options="jam"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Nominal (Rp)" label-cols-md="3">
          <b-form-input v-model="data.nominal"></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button variant="primary" @click="simpan()" :disabled="!isValid || busy">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id"
export default {
  components: { Multiselect, DatePicker },

  name: "modalInput",
  props: ["listKeterangan", "listKaryawan"],
  data() {
    return {
      moment,
      data: {
        dataKaryawanId: "",
        karyawan: "",
        nominal: "",
        jumlahJam: "",
        tanggalLembur:""
      },
      button: "Simpan",
      busy: false,
      jam: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      karyawan: {
        required,
      },
      tanggal: {
        required,
      },
      nominal: {
        required,
        numeric,
      },
      jumlahJam: {
        required,
      },
    },
  },
  mounted() {
    // this.getKaryawan();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      // console.log(x);
    },
    async getKaryawan() {
      let vm = this;
      let listKaryawan = await axios.get(
        ipBackend + "suratPerjanjianKerja/listPerjanjianDibuat"
      );

      // console.log(listKaryawan.data.data, "ini list karyawan");
      let penampung = listKaryawan.data.data;
      vm.listKaryawan = penampung.map((item) => {
        item.dataKaryawan =
          item.namaKaryawan + " - " + item.namaDivisi + " - " + item.namaPosisi;
        return item;
      });
    },

    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.tanggalLembur = moment(vm.data.tanggal)
      vm.data.dataKaryawanId = vm.data.karyawan.id;
      // console.log(vm.data.tanggalLembur)
      axios
        .post(ipBackend + "lembur/register", vm.data)
        .then((res) => {
          // console.log(res.data.message);
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL INPUT DATA LEMBUR KARYAWAN",
              showing: true,
            });
            vm.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
            vm.$bvModal.hide("modal-input");
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          // vm.$bvModal.hide("modal-input");
        });
    },

    // simpan() {
    //   let vm = this;
    //   vm.button = "Mohon Tunggu";
    //   vm.busy = true;
    //   let bulkAbsenKaryawan = [];
    //   let penampung = {};
    //   let penampung1 = {};
    //   let tgl = moment(vm.data.tglAbsenKaryawan).format("YYYY/MM/DD");
    //   vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
    //   vm.data.masterIjinId = vm.data.keteranganIjin.id;
    //   if (vm.data.statusIjin == 0) {
    //     penampung.tglAbsenKaryawan = `${tgl} ${vm.data.jamMasuk}`;
    //     penampung.statusIjin = 0;
    //     penampung1.tglAbsenKaryawan = `${tgl} ${vm.data.jamKeluar}`;
    //     penampung1.statusIjin = 0;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   } else if (vm.data.statusIjin == 1) {
    //     penampung.tglAbsenKaryawan = tgl;
    //     penampung.statusIjin = vm.data.statusIjin;
    //     penampung.masterIjinId = vm.data.masterIjinId;
    //     penampung1.tglAbsenKaryawan = tgl;
    //     penampung1.statusIjin = vm.data.statusIjin;
    //     penampung1.masterIjinId = vm.data.masterIjinId;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   } else {
    //     penampung.tglAbsenKaryawan = tgl;
    //     penampung.statusIjin = vm.data.statusIjin;
    //     penampung1.tglAbsenKaryawan = tgl;
    //     penampung1.statusIjin = vm.data.statusIjin;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   }
    //   axios
    //     .post(ipBackend + "absensiKaryawan/register", {
    //       dataKaryawanId: vm.data.dataKaryawanId,
    //       bulkAbsenKaryawan: bulkAbsenKaryawan,
    //     })
    //     .then((res) => {
    //       if (res.data.message == "sukses") {
    //         vm.button = "Simpan";
    //         vm.busy = false;
    //         vm.$emit("alertFromChild", {
    //           variant: "success",
    //           msg: "BERHASIL INPUT DATA ABSEN KARYAWAN",
    //           showing: true,
    //         });
    //         vm.data.dataKaryawanId = "";
    //         vm.data.tglAbsenKaryawan = "";
    //         vm.data.jamMasuk = "";
    //         vm.data.jamKeluar = "";
    //         vm.$bvModal.hide("modal-input");
    //       } else {
    //         vm.button = "Simpan";
    //         vm.busy = false;

    //         vm.$emit("alertFromChild", {
    //           variant: "danger",
    //           msg: _.toUpper(res.data.message),
    //           showing: true,
    //         });
    //         vm.$bvModal.hide("modal-input");
    //       }
    //     })
    //     .catch((err) => {
    //       vm.button = "Simpan";
    //       vm.busy = false;
    //       vm.$emit("alertFromChild", {
    //         variant: "danger",
    //         msg: "TERJADI KESALAHAN PADA SERVER",
    //         showing: true,
    //       });
    //       vm.$bvModal.hide("modal-input");
    //     });
    // },
  },
};
</script>
